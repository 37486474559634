import React from 'react'
import './modern-normalize.css'
import styles from './index.module.css'
import background from './background.png'
import desktopBackground from './desktopBackground@2x.png'
import firstGet from './firstGet.png'
import apply from './apply.png'

const NewYear = () => (
  <div className={styles.wrapper}>
    <img src={background} alt="" className={styles.mobile} />
    <img src={desktopBackground} alt="" className={styles.desktop} />

    <a href="http://atlaspio.mikecrm.com/q3rTS59" target="blank" className={styles.firstGet}>
      <img src={firstGet} alt="" />
    </a>

    <a href="http://atlaspio.mikecrm.com/q3rTS59" target="blank" className={styles.apply}>
      <img src={apply} alt="" />
    </a>
  </div>
)

export default NewYear
